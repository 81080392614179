import React, { Component } from 'react';
import './svg.css'

class CloseIcon extends Component {
    render() {
        return (
        <svg className="close icon light"
            viewBox="0 0 100 100"
            version="1.1">
            <path className="color-fill" d="M50,1.469C76.785,1.469 98.531,23.215 98.531,50C98.531,76.785 76.785,98.531 50,98.531C23.215,98.531 1.469,76.785 1.469,50C1.469,23.215 23.215,1.469 50,1.469ZM64.481,49.998L80.739,33.736C84.735,29.739 84.734,23.25 80.737,19.254C76.74,15.259 70.252,15.26 66.256,19.256L49.998,35.519L33.736,19.261C29.739,15.265 23.25,15.266 19.254,19.263C15.259,23.26 15.26,29.748 19.256,33.744L35.519,50.002L19.261,66.264C15.265,70.261 15.266,76.75 19.263,80.746C23.26,84.741 29.748,84.74 33.744,80.744L50.002,64.481L66.264,80.739C70.261,84.735 76.75,84.734 80.746,80.737C84.741,76.74 84.74,70.252 80.744,66.256L64.481,49.998Z"/>
        </svg>
        );
    }
}

export default CloseIcon;
