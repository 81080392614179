import React, { Component } from 'react';
import './svg.css';

class WolfcraftLogoLight extends Component {
    render() {
        return (
            <svg className="wolfcraft-io logo light" 
                viewBox="0 0 709 709" 
                version="1.1">
                <g transform="matrix(4.16667,0,0,4.16667,56.25,0)">
                    <path className="color-fill" d="M120.802,68.613L80.659,68.613L80.659,36.323L89.837,36.323L94.706,28.466L107.722,28.466L107.722,32.307L96.845,32.307L91.98,40.164L84.636,40.164C84.644,40.186 84.624,64.626 84.63,64.675C84.665,64.667 116.601,64.675 116.8,64.655C116.818,64.46 116.791,48.51 116.826,44.815C116.832,44.215 116.625,44.027 116.132,44.027C114.273,44.051 102.275,44.055 100.99,44.064C98.757,44.083 96.7,45.109 95.493,46.804C94.415,48.319 94.167,50.161 94.823,51.862C95.839,54.503 98.022,56.078 100.673,56.078C102.345,56.078 103.935,55.413 105.029,54.255C106.791,52.388 107.285,50.46 106.587,48.181C106.351,47.419 105.865,45.836 107.667,45.277C107.917,45.199 108.158,45.162 108.388,45.162C109.837,45.162 110.273,46.567 110.437,47.097C111.546,50.663 110.882,53.925 108.519,56.533C105.73,59.618 101.949,60.697 97.835,59.563C93.763,58.442 91.185,55.596 90.569,51.547C90.132,48.654 90.933,45.864 92.825,43.686C94.669,41.569 97.368,40.314 100.224,40.24C102.816,40.172 105.41,40.161 108.003,40.164L108.003,36.295L116.839,36.295L116.839,40.203C117.296,40.205 120.802,40.224 120.802,40.224L120.802,42.75C120.812,49.219 120.802,68.613 120.802,68.613Z"/>
                    <path className="color-fill" d="M103.009,50.373C103.001,51.646 102.142,52.493 100.82,52.534L100.73,52.534C99.374,52.534 98.48,51.682 98.462,50.367C98.442,49.072 99.292,48.211 100.679,48.108C102.195,48.216 103.017,49.064 103.009,50.373Z"/>
                    <path className="color-fill" d="M40.435,112.047C41.401,112.028 41.93,111.471 42.229,110.511C43.486,106.491 44.769,102.476 46.03,98.458C46.463,97.078 45.986,96.369 44.57,96.361C43.654,96.252 43.036,96.763 42.711,97.794C41.447,101.807 40.16,105.817 38.907,109.838C38.446,111.31 39.016,112.077 40.435,112.047Z"/>
                    <path className="color-fill" d="M33.668,111.047C33.988,111.383 34.827,111.557 35.198,111.34C36.365,110.64 36.571,109.029 35.709,108.108C34.559,106.887 33.53,105.525 32.356,104.11C33.101,103.258 33.818,102.465 34.502,101.642C35.051,100.984 35.664,100.353 36.067,99.592C36.581,98.618 36.112,97.672 35.393,97.14C35.006,96.852 34.061,96.855 33.759,97.177C31.939,99.1 30.21,101.126 28.499,103.169C27.987,103.781 27.963,104.593 28.51,105.232C30.197,107.199 31.897,109.168 33.668,111.047Z"/>
                    <path className="color-fill" d="M49.459,111.193C50.105,111.832 50.805,111.703 51.592,110.799C53.149,109.009 54.709,107.214 56.268,105.424C56.991,104.589 57.005,103.726 56.315,102.944C54.748,101.166 53.183,99.385 51.609,97.611C50.731,96.617 50.096,96.501 49.426,97.193C48.528,98.128 48.414,99.109 49.165,100.051C50.243,101.402 51.368,102.713 52.572,104.161C51.411,105.53 50.264,106.863 49.141,108.215C48.392,109.115 48.53,110.271 49.459,111.193Z"/>
                    <path className="color-fill" d="M59.196,118.023L25.765,118.023L25.765,90.186L59.197,90.119L59.196,118.023ZM27.322,85.097C28.055,85.163 28.451,85.598 28.448,86.395C28.442,87.148 28.041,87.585 27.365,87.606C26.641,87.627 26.197,87.19 26.19,86.38C26.179,85.584 26.592,85.159 27.322,85.097ZM31.419,85.097C32.148,85.163 32.549,85.598 32.542,86.395C32.539,87.148 32.139,87.585 31.464,87.606C30.736,87.627 30.297,87.19 30.287,86.38C30.274,85.584 30.69,85.159 31.419,85.097ZM35.44,85.097C36.171,85.163 36.571,85.598 36.564,86.395C36.559,87.148 36.158,87.585 35.483,87.606C34.759,87.627 34.318,87.19 34.307,86.38C34.299,85.584 34.713,85.159 35.44,85.097ZM22.343,81.539L22.343,121.686L62.495,121.686L62.495,81.539L22.343,81.539Z"/>
                    <path className="color-stroke" d="M117.753,125.418L71.288,157.312C54.657,145.92 24.792,125.296 24.792,125.296C18.827,120.815 14.624,117.542 12.3,111.436C10.888,107.736 10.553,102.689 10.481,101.122L10.481,25.777L71.288,11.487L132.091,25.777L132.091,101.123C132.021,102.69 131.685,107.738 130.272,111.438C127.945,117.546 123.744,120.914 117.753,125.418Z" style={{ strokeWidth: '2.42px' }}/>
                    <path className="color-stroke" d="M125.443,129.893L71.288,167.064C51.906,153.787 17.1,129.751 17.1,129.751C10.148,124.527 7.249,120.714 4.541,113.597C2.895,109.286 2.504,103.402 2.421,101.577L2.421,19.628L71.288,2.974L140.154,19.628L140.154,101.578C140.068,103.404 139.679,109.288 138.033,113.6C135.32,120.719 132.423,124.644 125.443,129.893Z" style={{ strokeWidth: '4.84px' }}/>
                    <path className="color-fill" d="M120.82,98.335L117.73,98.335L117.73,87.3L96.396,109.422L107.429,109.422L107.429,113.259C107.258,113.269 89.493,113.259 89.493,113.259L89.491,94.82L93.288,94.82L93.288,107.739L114.931,84.897L103.957,84.897L103.957,81.539L120.802,81.539L120.82,98.335ZM115.453,121.686L80.653,121.686L80.653,87.608L97.542,87.608L97.542,90.965L83.772,90.965L83.772,118.329L112.38,118.329L112.38,106.446L115.453,106.446L115.453,121.686ZM71.389,76.909L71.389,153.17C86.856,142.576 114.628,123.397 114.628,123.397C120.176,119.229 125.085,116.186 127.246,110.507C128.56,107.066 128.871,102.372 128.938,100.916L128.938,76.909L71.389,76.909Z"/>
                    <path className="color-fill" d="M28.822,31.825L25.458,31.825L25.458,40.375L27.3,34.442L28.822,31.825Z"/>
                    <path className="color-fill" d="M42.03,38.883L47.311,44.245L59.377,46.924C58.758,52.497 55.486,53.784 55.486,53.784L59.412,62.285L59.412,31.825L31.123,31.825L39.193,39.528L42.03,38.883Z"/>
                    <path className="color-fill"  d="M31.755,55.345C32.916,51.739 27.355,45.001 25.458,42.842L25.458,65.255L31.18,65.255L31.755,55.345Z"/>
                    <path className="color-fill" d="M62.49,68.613L58.838,68.613L57.571,65.454L57.606,65.454L57.516,65.255L57.327,64.844L51.525,52.17L54.315,51.417C54.529,51.286 55.318,50.729 55.908,49.292L45.854,46.975L41.139,42.113L38.415,42.721L32.242,36.75L32.243,36.75L29.572,34.13C28.987,34.714 29.022,35.581 29.543,36.209L27.452,39.645L28.37,41.878C30.855,44.733 35.729,51.056 34.543,55.944L34.083,65.255L34.075,65.454L34.079,65.454L33.999,68.093L33.983,68.613L22.337,68.613L22.337,28.466L62.49,28.466L62.49,68.613ZM41.885,46.34C41.437,46.744 40.746,46.705 40.343,46.256C39.942,45.805 39.978,45.115 40.43,44.712C40.452,44.691 40.481,44.673 40.509,44.653L41.953,46.266C41.93,46.29 41.911,46.317 41.885,46.34ZM13.739,27.768L13.739,76.909L71.311,76.909L71.311,14.762L13.739,27.768Z"/>
                </g>
            </svg>
        );
    }
}

export default WolfcraftLogoLight;