import React, { Component } from 'react';
import './svg.css'

class MailIcon extends Component {
    render() {
        return (
            <svg className="mail icon light"
                viewBox="0 0 1000 1000" 
                version="1.1">
                <g transform="matrix(0.1,-4.34664e-18,4.34664e-18,-0.135493,1.44242e-14,516.092)">
                    <g>
                        <path className="color-fill" d="M1232.5,3694.7C767.3,3667.8 419.4,3441 215.7,3029.6C92.7,2781.6 96.5,2922 102.3,50.2L108.1,-2560.1L160,-2704.3C284.9,-3044.5 563.7,-3321.3 902,-3438.6L1030.8,-3482.8L8988.8,-3482.8L9121.4,-3430.9C9471.2,-3288.7 9723,-3033 9848,-2685.1L9892.2,-2560.1L9898,50.2C9903.8,2922 9907.6,2783.6 9784.6,3033.5C9628.9,3350.7 9346.3,3579.4 9002.3,3667.8C8892.7,3696.6 8516,3700.5 5157.9,3702.4C3110.5,3704.3 1344,3700.4 1232.5,3694.7ZM8419.6,2779.7C8417.7,2752.8 5082.6,400 5051.9,401.9C5025,403.9 1886,2766.2 1886,2785.5C1886,2789.3 3356.5,2793.2 5153.7,2793.2C6951,2793.2 8421.5,2787.4 8419.6,2779.7ZM3024,765.2C4121.6,-65.2 5026.9,-743.7 5036.5,-743.7C5048,-743.7 5936.1,-117 7012.5,646.1L8969.3,2037.8L8975.1,-184.3C8978.9,-2306.4 8977,-2570.67 8944.3,-2614.87C8925.1,-2641.77 8884.7,-2680.27 8855.9,-2701.37L8804,-2741.77L1196,-2771.29L1144.1,-2730.89C1115.3,-2709.79 1073,-2667.49 1051.8,-2638.59C1011.4,-2586.69 1011.4,-2383.3 1005.7,-59.3C1003.8,1224.7 1007.6,2274.3 1015.3,2274.3C1024.9,2274.2 1928.3,1595.6 3024,765.2Z"/>
                    </g>
                </g>
            </svg>
        );
    }
}

export default MailIcon;
